.msg-submit-button {
    background-color: #0e9f5c;
    border-radius: 4px;
    width: 20vw;
    height: 5vh;
}

.p-inline {
    width: 40vw;
}

.text-message-inline {
    width: 30vw;
    height: 20vh;
}

.text-option-inline {
    width: 15vw;
    height: 10vh;
}