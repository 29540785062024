/* Path: src/css/container.css */
.flex-main-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100vh;
    width: 100vw;
    min-height: 100vh;
    min-width: 100vw;
}

/* css that splits header body and footer into 10%, 80% and 10% each */
.row-header {
    flex-grow: 1;
}

.row-body {
    flex-grow: 8;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.row-body-left {
    flex-grow: 2;
}

.row-body-center {
    flex-grow: 6;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
}

.row-body-right {
    flex-grow: 2;
}

.row-footer {
    flex-grow: 1;
}
