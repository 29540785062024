.header-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.header-logo {
    flex-grow: 1;
    text-align: left;
}

.header-links-flex {
    flex-grow: 8;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.header-links {
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
}

.header-logout {
    flex-grow: 1;
    text-align: right;
}

.logo-text {
    color: #0e9f5c;
}

.logout-button {
    background-color: #0e9f5c;
    border-radius: 4px;
    width: 200px;
    height: 30px;
}