.phone-number-input {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;
}

.isd-drop-down {
    width: 100px;
}

.otp-button {
    background-color: #0e9f5c;
    border-radius: 4px;
    width: 15vw;
    height: 5vh;
}